import { Cloneable } from '../cloneable.model';
import { Serializable } from '../serializable.model';
import { ServiceCategory } from '../service-category/service-category.model';
import { ServiceDefinitionJSON } from './service-definition-json.model';
import { ServiceDefinitionSerialized } from './service-definition-serialized.model';
import { Stylist } from '../stylist/stylist.model';

export class ServiceDefinition
  implements
    Cloneable<ServiceDefinition, ServiceDefinitionJSON>,
    Serializable<ServiceDefinitionSerialized> {
  public readonly categoryID: number;
  public readonly clientBookable: number;
  public readonly description: string;
  public readonly duration: number;
  public readonly finishDuration: number;
  public readonly id: number;
  public readonly name: string;
  public readonly parentID: number;
  public readonly price: number;
  public readonly processDuration: number;
  public readonly serviceCategory: ServiceCategory;
  public readonly startingAt: number;
  public readonly stylist: Stylist;

  constructor(options: ServiceDefinitionJSON) {
    this.id = options.id;
    this.name = options.name;
    this.duration = options.duration;
    this.processDuration = options.processDuration;
    this.finishDuration = options.finishDuration;
    this.price = options.price;
    this.clientBookable = options.clientBookable;
    this.stylist = options.stylist;
    this.parentID = options.parentID;
    this.categoryID = options.categoryID;
    this.description = options.description;
    this.startingAt = options.startingAt;
    this.serviceCategory = options.serviceCategory;
  }

  public static parseServiceDefinition(data: Object): ServiceDefinition {
    return new ServiceDefinition({
      id: data['id'],
      name: data['name'],
      duration: data['duration'],
      processDuration: data['processDuration'],
      finishDuration: data['finishDuration'],
      price: data['price'],
      clientBookable: data['clientBookable'],
      stylist: new Stylist({
        id: data['stylistID'],
        firstName: data['stylistFirstName'],
        lastName: data['stylistLastName'],
        title:undefined,
        photoUrl: data['stylistPhotoUrl'],
        email: undefined,
        phone1: undefined,
        phone2: undefined,
        address1: undefined,
        address2: undefined,
        city: undefined,
        province: undefined,
        postal: undefined,
        country: undefined,
        administrator: undefined,
        isRebook: undefined,
        suggestRebookingBeforeCheckout: undefined,
        stylistKey: undefined,
        confirmApptsManually: undefined,
        calendar: undefined,
        viewAllCalendars: undefined,
        viewAllClients: undefined,
        settingsControl: undefined,
        hideDurations: undefined,
        hidePrices: undefined,
        canPush: undefined,
        cc: undefined,
        allowBookingsUntil: undefined,
        bookingHorizon: undefined,
        sendAppointmentReminderBefore: undefined,
        bookingInterval: undefined,
        letClientsRegister: undefined,
        letClientsCancel: undefined,
        bookingIntro: undefined,
        notifyForAllStaffActivity: undefined,
        newBookingNotification: undefined,
        editBookingNotification: undefined,
        deleteBookingNotification: undefined,
        waitListRequestNotification: undefined,
        intercomUserHash: undefined,
        canViewReports: undefined,
        active: undefined,
        abTest: undefined,
        fbID: undefined,
        cancellationPolicy: undefined,
        waitingList: undefined,
        allowCancellationUntil: '',
        letClientsUseFBLogin: undefined,
        minCancellation: '',
        sort: undefined,
        pronoun: undefined,
      customPronoun:undefined,
      isChatboxEnabled: true,
      isChatBubbleEnabled: true,
      }),
      parentID: data['parentID'],
      categoryID: data['categoryID'],
      description: data['description'],
      startingAt: data['startingAt'],
      serviceCategory: ServiceCategory.parseData(data['serviceCategory'])
    });
  }

  public clone(): ServiceDefinition {
    return new ServiceDefinition(this.toJSON());
  }

  public toJSON(): ServiceDefinitionJSON {
    return {
      id: this.getID(),
      parentID: this.getParentID(),
      categoryID: this.getCategoryID(),
      name: this.getServiceName(),
      duration: this.getDuration(),
      processDuration: this.getProcessDuration(),
      finishDuration: this.getFinishDuration(),
      price: this.getPrice(),
      clientBookable: this.isClientBookable() ? 1 : 0,
      stylist: this.getStylist(),
      description: this.getDescription(),
      startingAt: this.getStartingAt(),
      serviceCategory: this.serviceCategory
    };
  }

  public serialize(): ServiceDefinitionSerialized {
    return {
      stylistID: this.getStylist() ? this.getStylist().getID() : undefined,
      ...this.toJSON()
    };
  }

  public getID(): number {
    return this.id;
  }

  public setID(id: number): ServiceDefinition {
    const data: ServiceDefinitionJSON = this.toJSON();
    data.id = id;
    return new ServiceDefinition(data);
  }

  public getServiceName(): string {
    return this.name;
  }

  public setServiceName(serviceName: string): ServiceDefinition {
    const data: ServiceDefinitionJSON = this.toJSON();
    data.name = serviceName;
    return new ServiceDefinition(data);
  }

  public getDuration(): number {
    return this.duration;
  }

  public setDuration(duration: number): ServiceDefinition {
    const data: ServiceDefinitionJSON = this.toJSON();
    data.duration = duration;
    return new ServiceDefinition(data);
  }

  public getProcessDuration(): number {
    return this.processDuration;
  }

  public setProcessDuration(processDuration: number): ServiceDefinition {
    const data: ServiceDefinitionJSON = this.toJSON();
    data.processDuration = processDuration;
    return new ServiceDefinition(data);
  }

  public getFinishDuration(): number {
    return this.finishDuration;
  }

  public setFinishDuration(finishDuration: number): ServiceDefinition {
    const data: ServiceDefinitionJSON = this.toJSON();
    data.finishDuration = finishDuration;
    return new ServiceDefinition(data);
  }

  public getPrice(): number {
    return this.price;
  }

  public setPrice(price: number): ServiceDefinition {
    const data: ServiceDefinitionJSON = this.toJSON();
    data.price = price;
    return new ServiceDefinition(data);
  }

  public isClientBookable(): boolean {
    return this.clientBookable === 1;
  }

  public setClientBookable(clientBookable: boolean): ServiceDefinition {
    const data: ServiceDefinitionJSON = this.toJSON();
    data.clientBookable = clientBookable ? 1 : 0;
    return new ServiceDefinition(data);
  }

  public getStylist(): Stylist {
    return this.stylist;
  }

  public setStylist(stylist: Stylist): ServiceDefinition {
    const data: ServiceDefinitionJSON = this.toJSON();
    data.stylist = stylist;
    return new ServiceDefinition(data);
  }

  public getParentID(): number {
    return this.parentID;
  }

  public setParentID(parentID: number): ServiceDefinition {
    const data: ServiceDefinitionJSON = this.toJSON();
    data.parentID = parentID;
    return new ServiceDefinition(data);
  }

  public getCategoryID(): number {
    return this.categoryID;
  }

  // public setCategoryID (categoryID: number) : ServiceDefinition {
  //   const data: ServiceDefinitionJSON = this.toJSON();
  //   data.categoryID = categoryID;
  //   return new ServiceDefinition(data);
  // }

  public getServiceCategory(): ServiceCategory {
    return this.serviceCategory;
  }

  public setServiceCategory(category: ServiceCategory): ServiceDefinition {
    const data: ServiceDefinitionJSON = this.toJSON();
    data.categoryID = category.id;
    data.serviceCategory = category;
    return new ServiceDefinition(data);
  }

  public isBookBack(): boolean {
    return this.processDuration !== 0 && this.finishDuration !== 0;
  }

  public getDescription(): string {
    return this.description;
  }

  public setDescription(description: string): ServiceDefinition {
    const data: ServiceDefinitionJSON = this.toJSON();
    data.description = description;
    return new ServiceDefinition(data);
  }

  public getStartingAt(): number {
    return this.startingAt;
  }

  public setStartingAt(startingAt: number): ServiceDefinition {
    const data: ServiceDefinitionJSON = this.toJSON();
    data.startingAt = startingAt;
    return new ServiceDefinition(data);
  }
}
